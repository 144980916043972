import React from "react"
import PropTypes from "prop-types"
import {graphql, useStaticQuery} from "gatsby"
import Header from "./header"
import "../styles/main.scss"
import styles from './layout.module.scss'
import BottomNavigationBar from "./bottom-navigation-bar"
import clsx from "clsx"

const Layout = ({className, children, location}) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  return (
    <div className={styles.layout}>
      <Header className={styles.header} siteTitle={data.site.siteMetadata.title}/>
      <main className={clsx(className, styles.main)}>
        {children}
      </main>
      <BottomNavigationBar location={location} className={styles.bottomNavigationBar}/>
    </div>
  )
}

Layout.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
