import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.svg"
import githubFilled from '@iconify/icons-ant-design/github-filled';
import linkedinFilled from '@iconify/icons-ant-design/linkedin-filled';
import styles from './header.module.scss'
import CustomIcon from "./custom-icon"
import Container from "./container"

const Header = ({siteTitle}) => (
  <header className={styles.header}>
    <Container>
      <div className={styles.header_container}>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="Logo"/>
        </Link>
        <div className={styles.links}>
          <div className={styles.section_link}>
            <Link to="/services">Services</Link>
            <Link to="/portfolio">Portfolio</Link>
            <Link to="/experience">Experience</Link>
            <Link to="/contact">Contact</Link>
          </div>
          <div className={styles.social_links}>
            <a href="https://github.com/amadogr50">
              <CustomIcon icon={githubFilled}/>
            </a>
            <a href="https://www.linkedin.com/in/amado-gonz%C3%A1lez-rodr%C3%ADguez-070335144/">
              <CustomIcon icon={linkedinFilled}/>
            </a>
          </div>
        </div>
      </div>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header