import React from 'react';
import {Link} from "gatsby"
import clsx from 'clsx'
import styles from './bottom-navigation-bar.module.scss'
import baselineWork from '@iconify/icons-ic/baseline-work';
import baselineMail from '@iconify/icons-ic/baseline-mail'
import timelineIcon from '@iconify/icons-fe/timeline'
import pricetagsIcon from '@iconify/icons-ion/pricetags';
import CustomIcon from "./custom-icon"
import PropTypes from "prop-types"
import {routes} from "../utils/routes"

const BottomNavigationBar = props => {
  const {className, location, ...others} = props

  const {pathname} = location

  return (
    <div {...others} className={clsx(styles.bottomNavigationBar, className)}>
      <div className={styles.itemsContainer}>
        <Link className={clsx(styles.item, pathname.includes(routes.services) && styles.itemActive)} to={routes.services}>
          <CustomIcon icon={baselineWork}/>
          <div className={styles.title}>Services</div>
        </Link>
        <Link className={clsx(styles.item, pathname.includes(routes.portfolio) && styles.itemActive)} to={routes.portfolio}>
          <CustomIcon icon={timelineIcon}/>
          <div className={styles.title}>Portfolio</div>
        </Link>
        <Link className={clsx(styles.item, pathname.includes(routes.experience) && styles.itemActive)} to={routes.experience}>
          <CustomIcon icon={pricetagsIcon}/>
          <div className={styles.title}>Experience</div>
        </Link>
        <Link className={clsx(styles.item, pathname.includes(routes.contact) && styles.itemActive)} to={routes.contact}>
          <CustomIcon icon={baselineMail}/>
          <div className={styles.title}>Contact</div>
        </Link>
      </div>
    </div>
  );
};

BottomNavigationBar.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired
}


export default BottomNavigationBar;