import {Icon} from '@iconify/react';
import React from "react"
import styles from './custom-icon.module.scss'
import PropTypes from "prop-types"
import clsx from "clsx"

const CustomIcon = ({className, icon}) => {
  return (
    <Icon className={clsx(styles.icon, className)} icon={icon}/>
  )
}

CustomIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element,
}

export default CustomIcon